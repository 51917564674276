@media(min-width: 600px){
    .page-content-box-container{
        flex-direction: row;
    }
    .page-content-box-container2{
        flex-direction: row;
        justify-content: right;
    }
    .page-content-box{
        max-width: 35rem;
        margin-top: -8rem;
        padding-inline: var(--space-m);
    }
    .page-content-box2{
        max-width: 35rem;
        margin-top: -8rem;
        padding-inline: var(--space-m);
    }


}
@media(max-width: 599px){
    .page-content-box-container{
        flex-direction: column;
    }
    .page-content-box-container2{
        flex-direction: column;
    }
}

@media(min-width: 1000px){
    .page-content-box{
        z-index: 1;
    }
    .page-content-box2{
        z-index: 1;
    }
    .page-content-box-banner{
        left: 25%;
        top: 0;
        position: absolute;
    }
    .page-content-box-banner2{
        right: 25%;
        top: 0;
        position: absolute;
    }
    .page-content-box-banner-box{
        position: absolute;
        right: 8%;
    }
    .page-content-box-banner-box2{
        position: absolute;
        left: 8%;
    }
}

.page-content-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: 100vw;
    overflow-x: hidden;
    height: max-content;
}

.page-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    min-width: 346px;
    max-width: 1440px;
    height: max-content;

}

.page-content-box-container{
    display: flex;
    gap: var(--space-xl);
    padding-inline: var(--space-m);
    padding-block: var(--space-xl);
    color: #fff;
    position: relative;

    width: 100%;
    min-width: 346px;
    max-width: 1440px;
    min-height: 84%;
    height: max-content;
    margin: 2rem 0px 32px 0px;
    box-sizing: border-box;
}

.page-content-box-container2{
    display: flex;
    gap: var(--space-xl);
    padding-inline: var(--space-m);
    padding-block: var(--space-xl);
    color: #fff;
    position: relative;

    width: 100%;
    min-width: 346px;
    max-width: 1440px;
    min-height: 84%;
    height: max-content;
    margin: 32px 0px 2rem 0px;
    box-sizing: border-box;
}

.page-content-box{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    min-width: 346px;
    min-height: 480px;
    height: max-content;

    border-radius: 64px;
    background-color: var(--colAA);
    padding-block: var(--space-xl);

    color: black;


}

.page-content-box2{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    min-width: 346px;
    min-height: 480px;
    height: max-content;

    border-radius: 64px;
    background-color: var(--colAA);
    padding-block: var(--space-xl);

    color: black;


}

.page-content-box-banner{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-width: 326px;
    max-width: 920px;
    min-height: 524px;
    height: max-content;
    padding-block: var(--space-xl);

    border-radius: 64px;

    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    image-rendering: crisp-edges;
}

.page-content-box-banner-box{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    height: 70%;

    padding-block: var(--space-xl);

}

.page-content-box-banner2{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-width: 326px;
    max-width: 920px;
    min-height: 480px;
    height: max-content;
    padding-block: var(--space-xl);

    border-radius: 64px;

    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    image-rendering: crisp-edges;
}

.page-content-box-banner-box2{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    height: 70%;

    padding-block: var(--space-xl);

}




.page-content-large-header{
    text-align: center;
    font-size: clamp(40px, 11vh, 11vw);
    font-weight: 400;
    text-shadow: 0px 3px 0.6vh var(--colC);
    backdrop-filter: blur(2px);

}

.page-content-header{
    text-align: center;
    font-size: clamp(32px, 6.2vh, 6.2vw);
    font-weight: 400;
    text-shadow: 0px 3px 0.6vh var(--colC);
    backdrop-filter: blur(2px);

}

.page-content-text{
    text-align: center;
    font-size: clamp(24px, 4.2vh, 4.2vw);
    font-weight: 400;
    text-shadow: 0px 1px 0.6vh var(--colC);
    backdrop-filter: blur(2px);
}

