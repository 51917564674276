@media(max-width:960px){
    .footer{
        flex-direction: column;
        align-items: center;
    }
}
@media(min-width:961px){
    .footer{
        flex-direction: row;
    }
}
.footer-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-content: center;
    align-items: center;
    width: 100vw;
    background-color: var(--colAA);
}
.footer{
    display: flex;
    justify-content: center;
    align-content: center;

    width: 100vw;
    min-width: 346px;
    max-width: 1440px;
    min-height: 256px;
    height: max-content;
    margin: 24px 0px 24px 0px;

}

.footer-content{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-content: center;
    align-items: center;
    text-align: center;
    width: 30%;
    margin: 4px;
    font-weight: 600;

}

.footer-links-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: x-large;
    margin: 8px 8px 12px 8px;
    text-shadow: 0px 1px 0.3vh var(--colE);
}


.footer-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor:pointer;
    font-weight: 400;
    font-size: large;
    margin: 6px;
    color: var(--colE);
    min-width: max-content;
    text-shadow: 0px 1px 0.3vh var(--colE);
}
.footer-links:hover{
    color: var(--colB);
    font-style:italic;
}

.footer-imprint-house{
    position: fixed;
    z-index: 11;
    top: 0vh;
    left: 0vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;


    width: 100vw;
    height: 100vh;
}
.footer-imprint-container{
    position: relative;
    z-index: 11;




    display: flex;
    flex-direction: column;
    justify-content: top;
    align-content: left;
    align-items: left;
    text-align: left;
    height: max-content;

    padding: 32px;
    min-width: 326px;
    width: 100%;
    max-width: 40vw;
    min-height:70vh;
    max-height: 70vh;

    background-color: var(--colAAA);
    border-radius: 16px;
    border-style: ridge;
    border-color: var(--colB);
    box-shadow: 0px 5px 3px 0 var(--colE);

    overflow-y: auto;

}

.footer-imprint-text-header{
    color: black;
    font-weight: 600;
    font-size: clamp(24px, 2.66vh, 2.66vw);
    margin: 8px 0px 32px 0px;
    text-shadow: 0px 1px 0.3vh var(--colE);
}

.footer-imprint-text-title{
    color: black;
    font-weight: 600;
    font-size: clamp(20px, 2.22vh, 2.22vw);
    margin: 6px 0px 6px 0px;
    text-shadow: 0px 1px 0.3vh var(--colE);
}

.footer-imprint-text{
    color: var(--colE);
    font-weight: 400;
    font-size: clamp(18px, 2vh, 2vw);
    margin: 4px 0px 4px 0px;
    text-shadow: 0px 1px 0.3vh var(--colE);
}
.footer-imprint-close{
    position: absolute;
    top: 0px;
    right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: clamp(52px, 6vh, 6vw);
    font-weight: 800;
    cursor:pointer;
    color:var(--colE);

    transition-property: scale, color, text-shadow;
    transition-duration: 160ms;
    text-shadow: 0px 1px 1vh var(--colE);
}
.footer-imprint-close:hover{
    scale: 1.25;
    color: var(--colC);

}