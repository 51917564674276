.navbar-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 128px;
    position: fixed;

    z-index: 5;
    backdrop-filter: blur(8px);


}
.navbar{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    min-width: 346px;
    max-width: 1440px;
}

.navbar-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;



    background-color: white;
    border-radius: 16px;
    border-style: ridge;
    border-color: var(--colB);
    box-shadow: 0px 5px 3px 0 var(--colE);
    padding: 16px 16px 12px 16px;

    transition-property: scale, box-shadow, background-color, text-shadow;
    transition-duration: 160ms;
    cursor:pointer;

    margin: 0px 6px 0px 6px;

}
.navbar-logo:hover{
    scale: 1.1;
    box-shadow: 0px 5px 5px 1px var(--colE);
    background-color: var(--colAAA);
}

.navbar-logo-image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-height: 36px;
    width: fit-content;

}
.navbar-symbol-image{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-height: 36px;
    width: fit-content;
}



.navbar-links-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;

}

.navbar-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor:pointer;
    font-weight: 600;
    font-size: 24px;
    margin: 6px;
    color: black;
    width: max-content;
    min-width:80px;

    transition-duration: 160ms;
    transition-property: text-shadow;

}




.navbar-menu-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    min-width: 96px;
    padding: 4px 6px 4px 6px;
    margin: 0px 8px 0px 8px;

    background-color: var(--colAA);
    border-radius: 16px;
    border-style: ridge;
    border-color: var(--colB);
    box-shadow: 0px 5px 3px 0 var(--colE);
    padding: 16px 16px 12px 16px;

    transition-property: scale, box-shadow, background-color;
    transition-duration: 160ms;
    cursor:pointer;

}

.navbar-menu-button:hover{
    scale: 1.1;
    box-shadow: 0px 5px 5px 1px var(--colE);
    background-color: var(--colAAA);
}
.navbar-menu-container{
    position: absolute;
    z-index: 2;
    top: 64px;
    left: calc(15vw - 16px);

    display: flex;
    flex-direction: column;
    justify-content: top;
    align-content: center;
    align-items: center;
    width: 192px;
    height: max-content;

    padding: 16px;
    width: 70vw;
    max-width: 70vw;
    overflow-x: hidden;

    /* background-color: var(--colAA);
    border-radius: 16px;
    border-style: ridge;
    border-color: var(--colB);
    box-shadow: 0px 5px 3px 0 var(--colE); */

}

.navbar-menu-background-fix{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 135vh;
    max-height: 135vh;
    background-color: var(--darkbg3);

    overflow-x: hidden;

    backdrop-filter: blur(8px);
}
