


:root {

  --border1: #75d8ff;
  --border2: #25c1ff;
  --border3: #257cff;
  --border4: #499eff;
  --border5: rgb(0, 204, 255);
  --darkBorder1: rgba(49, 39, 125, 0.664);
  --shadow1: 0 0px 1vh 0 #00a7f8;
  --shadow2: 0 0px 1.666vh 0 #00a1f8;
  --shadow3: 0 0px 1vh 0 #00a5f8aa;
  --shadowFav: 0 0px 1.666vh 0 #00ffc3da;
  --darkbg1: #03060c4b;
  --darkbg2: #03060c96;
  --darkbg3: #000000b7;
  --darkbg4: rgba(0, 0, 0, 0.948);
  --darkbg5: #03060c88;
  --text1: #00f7ff;
  --text2:hsl(182, 100%, 50%);
  --co: #000000;


  --shadowBionic: 0px 0px 1vh 0px #ff7057;
  --borderBionic: rgb(255, 50, 50);

  --borderSafety: #ae00ff;
  --textSafety: #d23eff;
  --shadowSafety: 0 0px 1vh 0 #c300ff;

  --borderSet: rgb(153, 255, 153);
  --borderSetOrange: #ffd698;
  --borderSetYellow: rgb(255, 255, 164);
  --borderSetRed: rgb(255, 115, 83);
  --borderSetRed2: #ff1d1d;
  --borderSetBlue: #79ffff;
  --shadowSet1: 0 0px 0.333vh 0 #00a7f8;
  --shadowSet2: 0 0px 0.888vh 0 #00a1f8;

  --colorA: #00f7ff;
  --colorB: #3AFDE5;
  --colorC: #6EFFC5;
  --colorD: #9DFFA5;
  --colorE: #CBFE87;
  --colorF: #F9F871;
  --colorG: #4D9DA0;
  --colorH: #E4FEFF;
  --colorI: #E6F4F1;
  --colorJ: #1b1b1b4a;

  --colAAA:rgb(254, 254, 255);
  --colAA: #f0f8ff;
  --colA: #BBCEDD;
  --colB: #7D98B2;
  --colC: #617D98;
  --colD: #3D5672;
  --colE: #33475F;

  --space-m: clamp(1.5rem, calc(1.29rem + 1.07vw), 2.25rem);
  --space-xl: clamp(3rem, calc(2.57rem + 2.14vw), 4.5rem);


}

*::-webkit-scrollbar{
  width: clamp(4px, 0.8vw, 0.8vw);
}
*::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 clamp(0.2vw, 4px, 4px) var(--colC);
border-radius: 16px;
}
*::-webkit-scrollbar-thumb{
border-radius: 20px;
outline: 20px groove var(--colC);
outline-offset: -20px;

}



html {
  font-family: 'Cinzel';
  font-weight: 400;
  color: rgb(0, 0, 0);

  scroll-behavior: smooth;


}




body {
  position: relative;
  min-width: 346px;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-image: url("../images/Doyen Games Background (2).svg");
  background-position: top center;
  background-size:cover;
  image-rendering: default;
  background-repeat: repeat-y;
  min-width: calc(100vw);
  max-width: calc(100vw);
  min-height: calc(100vh);

  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}



.page-container {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  height: 100%;
  width: 100%;

  min-width: calc(100vw);
  max-width: calc(100vw);
  min-height: calc(200vh);






}

